import React from "react";
import "./style.css"

function SPproceets (){

    return(
        <div>
            <h2>PACE AL MOSTRADOR PARA FIRMAR EL CONSENTIMIENTO</h2>
            <br/>
            <h4>! GRACIAS !</h4>
        </div>
    );
}

export default SPproceets;