import React from "react";
import { Link } from "react-router-dom";


function Footer() {

    return (

        <div>
            <nav>
                <div className="container-fluid">
                <button type="button" className="btn btn-outline-danger" fdprocessedid="t7kb4"><Link to ="/" className="alink">Home </Link></button>
                <br/>
                <br />
                </div>
            </nav>
           

        </div>


    );
}

export default Footer;