import React from "react";
import "./style.css"

function Proceets (){

    return(
        <div>
            <h2>NOW PROCEED TO THE FRONT DESK TO SIGN THE CONSENT FORM</h2>
            <br/>
            <h4> Thanks!</h4>
            <br/>
                </div>
    );
}

export default Proceets;