import React from "react";

function Jumbotron (){

    return (
    
    <div className="container">
    <div className="jumbotron ">
<h1 className="display-4 fw-semibold">Cesar Zamora DDS </h1>
<h3 className="display-6 fw-medium">Consent Forms</h3>
<p className="lead">
    
</p>
<p className="lead">
</p>
</div>

    </div>
    );
}

export default Jumbotron;